<template>
  <div class="expand-photo__wrapper">
    <img
      :src="img"
      :alt="name"
    >
  </div>
</template>

<script>
export default {
  name: 'ModalImage',
  props: {
    img: {
      type: String
    },
    name: {
      type: String
    }
  },
  mounted() {
    window.onpopstate = function (events) {
      const event = new Event('click');
      const dropdownEl = document.querySelector('.r-modal__content-r-icon_close');
      if (dropdownEl) {
        dropdownEl.addEventListener('click', () => function (es) {
        });
        dropdownEl.dispatchEvent(event);
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.expand-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--rir-amelie);
  z-index: 9999;
  overflow: auto;
  box-sizing: content-box;

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
  }

  &__main-photo {
    height: 100%;
    border-radius: 15px;
  }
}
</style>
