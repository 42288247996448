<template>
  <div>
    <loader v-if="isLoading" />
    <div
      v-else
      class="orgs mt-8"
    >
      <div class="orgs__cards">
        <div
          v-for="item in objects"
          :key="item.id"
          class="flex"
        >
          <router-link
            :key="item.id"
            :to="{
              name: 'info',
              query: { id: item.id },
            }"
            class="size"
          >
            <object-card
              :id="item.id"
              :name="item.title"
              :address="item.address"
              :img="item.img ? `/cityProperty/rest${item.img[0] === '/' ? item.img : '/' + item.img}` : null"
              icon="telephone"
              @mouseEnter="cardHover"
              @mouseLeave="cardHover"
            >
              <span v-if="item.phones && item.phones.length > 0">{{ item.phones[0] }}</span>
            </object-card>
          </router-link>
          <div
            :key="count"
            @click="operateFavoriteCard(item.id, item.title)"
          >
            <r-tooltip

              :title="!favoriteIds[item.id] ? 'в избранное' : 'Удалить из избранного'"
              position="top-center"
              align-title="end"
              max-width="200px"
            >
              <template #activator>
                <r-icon
                  class="mt-1 ml-auto pointer"
                  :icon="!favoriteIds[item.id] ? 'favorite-add' : 'favorite-add-active'"
                  size="16"
                  fill="rocky"
                />
              </template>
            </r-tooltip>
          </div>
        </div>
      </div>
      <div class="orgs__map-cont">
        <r-input
          class="mb-6 orgs__input-search"
          label="Поиск"
          v-model="search"
          before-icon="search"
        />

        <div
          :class="['orgs__map', { 'orgs__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="isShowMap"
            v-model="search"
            search
            @mapInit="initMap"
            :center="mapCenter"
            @markersWasAdd="markersWasAdd"
          >
            <div
              v-show="marker.id !== 'searchMarker'"
              v-for="marker of filteredObjects"
              :key="`org_${marker.id}_${search}`"
            >
              <ymap-marker

                v-if="marker.coordinates"
                :coords="marker.coordinates"
                :marker-id="`org_${marker.id}`"
                :icon="$markerIcon()"
                :balloon="{
                  header: {
                    id: marker.id,
                    img: marker.img ? `/cityProperty/rest/${marker.img}` : null,
                    title: marker.title,
                    name: marker.address,
                    coordinates: marker.coordinates,
                  },
                }"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="main"
                @click="onMarkerClick(marker.id)"
                @balloonopen="bindListener($event, marker.id, favoriteIds[marker.id], marker.title)"
                @balloonclose="unbindListener($event, marker.id, favoriteIds[marker.id], marker.title)"
              >
                <balloon-card
                  slot="balloon"
                  :title="marker.title"
                  :address="marker.address"
                  :img="marker.img ? `/cityProperty/rest/${marker.img}` : null"
                  :data-id="marker.id"
                  :id="marker.id"
                  :favorite="favoriteIds[marker.id]"
                >
                  <a
                    :href="'/create-route?toCoords=' + marker.coordinates.join(',')"
                    class="flex mt-3"
                  >
                    <r-icon
                      size="16"
                      fill="rocky"
                      class="mr-2"
                      icon="geopoint"
                    />
                    <span class="briscola color-rocky">Построить маршрут</span>
                  </a>
                </balloon-card>
              </ymap-marker>
            </div>
            <ymap-marker
              v-show="searchCoordinates.id === 'searchMarker'"
              v-if="searchCoordinates.coordinates"
              :coords="searchCoordinates.coordinates"
              :marker-id="`org_${searchCoordinates.id}`"
              :icon="$markerIcon()"
              :options="{ hideIconOnBalloonOpen: false }"
              cluster-name="main"
            />

            <!-- Обход массива геометрии (с бэка приходят как полигоны, так и мультиполигоны) -->
            <div
              v-for="district in objects"
              :key="`district_${district.id}_${search}`"
            >
              <div
                v-for="(geometry, index) in district.geometry"
                :key="`district_${index}_${search}`"
              >
                <ymap-marker
                  :marker-id="'district_' + district.id + '-' + index"
                  marker-type="Polygon"
                  :coords="geometry"
                  :hint-content="district.title"
                  :marker-fill="{ color: district.id === polygonHoverId ? '#D06E0B' : '#3d75e4', opacity: 0.16 }"
                  :marker-stroke="{ color: district.id === polygonHoverId ? '#D06E0B' : '#3d75e4', width: 1 }"
                  fill-rule="nonZero"
                  @click="pushTo(district.id)"
                  :options="{
                    zIndex: 9,
                  }"
                />
              </div>
            </div>
            <div v-if="!!filteredObjects && !!searchText">
              <ymap-marker
                :coords="[coordinates[0], coordinates[1]]"
                :marker-id="'marker'"
                :options="{
                  hideIconOnBalloonOpen: false,
                  draggable: false,
                }"
                :icon="$markerIcon()"
              />
            </div>
          </rir-map>
          <!-- <loading-content v-else /> -->
          <button
            class="orgs__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
        <!--<a
          href="https://dgjn.yanao.ru/request/info/"
          class="mt-8px flex items-center"
          target="_blank"
        >
          <svg
            width="16"
            height="16"
            class="inline-block no-shrink color-rocky"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M5.88835e-05 3C5.88835e-05 1.34315 1.3432 0 3.00006 0H13.0001C14.6569 0 16.0001 1.34315 16.0001 3V10C16.0001 11.6569 14.6569 13 13.0001 13H5.33339L1.60006 15.8C1.15823 16.1314 0.53143 16.0418 0.200059 15.6C-0.131312 15.1582 -0.0417689 14.5314 0.400059 14.2L4.40006 11.2C4.57315 11.0702 4.78369 11 5.00006 11H13.0001C13.5523 11 14.0001 10.5523 14.0001 10V3C14.0001 2.44772 13.5523 2 13.0001 2H3.00006C2.44777 2 2.00006 2.44772 2.00006 3V11C2.00006 11.5523 1.55234 12 1.00006 12C0.447774 12 5.88835e-05 11.5523 5.88835e-05 11V3Z"
              fill="currentColor"
            />
            <path
              d="M4 5C4 4.44772 4.44772 4 5 4H11C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6H5C4.44772 6 4 5.55228 4 5Z"
              fill="currentColor"
            />
            <path
              d="M5 7C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H9C9.55229 9 10 8.55229 10 8C10 7.44772 9.55229 7 9 7H5Z"
              fill="currentColor"
            />
          </svg>
          <div class="ml-6px text-c16 color-rocky">Написать обращение в контролирующий орган</div>
        </a>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import ObjectCard from '@/components/ObjectCard.vue';
import RirMap from '../components/RirMap.vue';
import BalloonCard from '../components/BalloonCard.vue';
import Api from '../api/Api';

import Loader from '../components/Loader';

export default {
  name: 'Home',
  components: {
    Loader,
    ObjectCard,
    RirMap,
    BalloonCard
  },

  data() {
    return {
      files: [],
      selectedCategory: 3,
      isMapCollapsed: false,
      searchText: null,
      timeout: null,
      selectedMarkerId: null,
      isShowMap: false,
      coordinates: [47.5259165, 42.1899268],
      map: null,
      mapCenter: null,
      searchCoordinates: [],
      polygonHoverId: null,
      favorite: [],
      favoriteIds: [],
      favoriteCount: 0,
      count: 0,
      selectedMarkerTitle: '',
      eventId: null,
      onEvent: false
    };
  },

  computed: {
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = val;
          this.onCoordinates(val);
        }, 420);
      }
    },
    objects() {
      const _this = this;
      let allObjects = [];
      const i = 0;
      allObjects = this.$store.state.organizations.map(el => {
        _this.favoriteIds[el.id] = false;
        if (_this.favorite.fav != undefined) {
          const fav = _this.favorite.fav.filter(els => el.id == els.id);
          if (fav.length > 0) {
            _this.favoriteIds[el.id] = true;
          }
        }
        return {
          sort: _this.favoriteIds[el.id],
          ...el,
          geometry: el?.geometry?.type
            ? el.geometry.type === 'Polygon'
              ? [el.geometry.coordinates]
              : el.geometry.coordinates
            : [],
          coordinates: el?.coordinates?.coordinates ? el.coordinates.coordinates : null
        };
      });
      allObjects = allObjects.sort((a, b) => b.sort - a.sort);

      return allObjects;
    },
    filteredObjects() {
      const _this = this;
      let allObjects = [];
      allObjects = this.$store.state.organizations.map(el => {
        _this.favoriteIds[el.id] = false;
        if (_this.favorite.fav != undefined) {
          const fav = _this.favorite.fav.filter(els => el.id == els.id);
          if (fav.length > 0) {
            _this.favoriteIds[el.id] = true;
          }
        }
        return {
          sort: _this.favoriteIds[el.id],
          ...el,
          geometry: el?.geometry?.type
            ? el.geometry.type === 'Polygon'
              ? [el.geometry.coordinates]
              : el.geometry.coordinates
            : [],
          coordinates: el?.coordinates?.coordinates ? el.coordinates.coordinates : null
        };
      });

      /* if (this.searchText) {
         allObjects = allObjects.filter(el =>
           el?.address
             ? this.onAddress(el.address)
             : false
         );
       } */
      if (this.searchText) {
        allObjects = [];
      }
      allObjects = allObjects.sort((a, b) => b.sort - a.sort);

      return allObjects;
    },

    isLoading() {
      return this.$store.state.isOrganizationsLoading;
    }
  },
  activated() {
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },

  async mounted() {
    !this.$store.state.organizations.length && (await this.$store.dispatch('getOrganizations'));
    const api = new Api();
    this.favorite = await api.getFavorite();
  },

  methods: {
    cardHover(id) {
      this.polygonHoverId = id;
    },
    pushTo(id) {
      this.$router.push({
        name: 'info',
        query: { id }
      });
    },
    markersWasAdd() {
      if (window.innerWidth > 700) {
        this.centeredMap();
      }
    },
    initMap(e) {
      this.map = e;
    },
    async searchAddress() {
      const api = new Api();
      const res = await api.getAddress(JSON.stringify({ address: this.searchText }));

      this.searchCoordinates = { id: 'searchMarker', coordinates: [Number(res.lat), Number(res.lng)] };
    },
    centeredMap() {
      if (this.map && this.filteredObjects?.length > 0) {
        const pointsList = [];
        if (this.filteredObjects?.length > 0) {
          this.filteredObjects.forEach(item => {
            if (item?.coordinates) {
              pointsList.push([item?.coordinates[0], item?.coordinates[1]]);
            }
          });
        }
        this.$nextTick(() => {
          if (pointsList.length > 0) {
            this.map.setBounds(window.ymaps.util.bounds.fromPoints(pointsList), {
              checkZoomRange: true,
              zoomMargin: 100,
              duration: 300
            });
          }
        });
      }
    },

    onCoordinates: _.debounce(function (address) {
      if (address) {
        axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.$cityName} ${address.trim()}&format=json&lang=ru_RU&kind=house`)
          .then(result => {
            const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;
            if (geo) {
              this.coordinates = geo.Point?.pos?.split(' ').reverse();
              console.log(this.coordinates);
              this.searchCoordinates = { id: 'searchMarker', coordinates: this.coordinates.reverse() };
              // this.mapCenter = this.coordinates
              this.map.setBounds(window.ymaps.util.bounds.fromPoints([this.searchCoordinates.coordinates]), {
                checkZoomRange: true,
                zoomMargin: 400,
                zoom: 12,
                duration: 300
              });
            }
          });
      }
    }, 100),
    onAddress(address) {
      let len = 0;
      const arr = this.searchText.toLowerCase().replace(/ +/g, ' ').trim().split(' ');
      arr.forEach(item => {
        if (address.toLowerCase().indexOf(item) >= 0) {
          len++;
        }
      });
      return address
        ? len == arr.length
        : false;
    },
    // routeToInfo(e) {
    //   if (e.target.closest('.r-map-balloon__info')) {
    //     this.$router.push({ name: 'info', query: { id: this.selectedMarkerId, type: 'district' } });
    //   }
    // },
    // onMarkerClick(e) {
    //   this.selectedMarkerId = e.originalEvent.target.properties._data.markerId;
    // },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },
    operateFavorite() {
      this.onEvent = true;
      const api = new Api();
      api.setFavorite(this.selectedMarkerId, this.selectedMarkerTitle);
      this.favoriteIds[this.selectedMarkerId] = !this.favoriteIds[this.selectedMarkerId];
      this.count++;
    },
    operateFavoriteCard(id, title) {
      const api = new Api();
      api.setFavorite(id, title);
      this.favoriteIds[id] = !this.favoriteIds[id];
      this.count++;
    },
    onBalloonClick() {
      if (!this.selectedMarkerId) return;
      if (!this.onEvent) {
        this.$router.push({
          name: 'info',
          query: { id: this.selectedMarkerId }
        });
      }
      this.onEvent = false;
      this.favoriteCount++;
    },
    iconBalloon(link) {
      if (this.favoriteIds[this.selectedMarkerId]) {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.01448C2 1.34963 3.34315 0 5
                            0H11C12.6569 0 14 1.34963 14 3.01448V13.9866C14 15.6717
                            12.0601 16.6085 10.7506 15.5558L8 13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      } else {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2.00966C4.44772 2.00966 4 2.45953
                            4 3.01448V13.9866L6.75061 11.7755C7.48105 11.1883
                            8.51895 11.1883 9.24939 11.7755L12 13.9866V3.01448C12 2.45953 11.5523 2.00966 11
                            2.00966H5ZM2 3.01448C2 1.34963 3.34315 0 5 0H11C12.6569
                            0 14 1.34963 14 3.01448V13.9866C14 15.6717 12.0601 16.6085 10.7506 15.5558L8
                            13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      }
    },
    // Обрабочики для Balloon'a карты
    bindListener(event, id, value, title) {
      console.log('222', this.selectedMarkerId);
      if (!this.selectedMarkerId) return;
      this.selectedMarkerTitle = title;
      this.eventId = event;
      const link = this.$refs.map.querySelector(
        `.link-balloon[link-id="${this.selectedMarkerId}"]`
      );
      this.iconBalloon(link);
      link.addEventListener('click', this.operateFavorite);

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    unbindListener(event, id, value, title) {
      console.log('222', this.selectedMarkerId);
      if (!this.selectedMarkerId) return;
      this.selectedMarkerTitle = title;
      this.eventId = event;
      const link = this.$refs.map.querySelector(
        `.link-balloon[link-id="${this.selectedMarkerId}"]`
      );
      this.iconBalloon(link);
      link.addEventListener('click', this.operateFavorite);
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },

    getPreviewImg(item) {
      if (this.selectedCategory === 0) return item.img;
      return item?.clinics?.[0]?.img ? item.clinics[0].img : null;
    }
  }
  // beforeDestroy() {
  //   this.$refs.map.removeEventListener('click', e => this.routeToInfo(e));
  // }
};
</script>
<style lang="scss" scoped>
.orgs {
  display: flex;
  justify-content: space-between;

  &__cards {
    width: 41.7%;
  }

  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }

  &__map {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}
.size {
  width: 94%;
}
@media screen and (max-width: 1023px) {
  .orgs {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map {
      height: 447px;
      margin-bottom: 32px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .orgs {
    &__input-search {
      display: none;
      // position: absolute;
      // z-index: 10;
      // background-color: #fff;
      // box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      // top: 16px;
      // left: 16px;
    }
  }
}

@media screen and (max-width: 599px) {
  .orgs {
    &__cards {
      margin-top: 16px;
    }
    &__map{
      height: 360px;
    }
    &__map--collapsed {
      height: 144px;
    }
  }
}
</style>
