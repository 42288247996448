<template>
  <div class="doc-view">
    <template v-if="isShowContent">
      <div
        class="flex align-items-center sulguni mb-4 color-rocky pointer"
        @click="isShowContent = false"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2 pointer"
          icon="arrow-left"
        />Назад
      </div>
      <div
        v-if="selectedDoc"
        class="doc-content"
      >
        <h1 class="mb-6">
          {{ selectedDoc.title }}
        </h1>
        <div
          v-if="selectedDoc.files.length"
          class="doc-view__file-cont"
        >
          <a
            class="color-rocky flex align-end"
            :href="`/service/company/document/file/${file.id}`"
            :download="file.title"
            v-for="file in selectedDoc.files"
            :key="file.id"
          >
            <r-icon
              size="16"
              fill="rocky"
              class="mr-2"
              icon="file"
            />
            {{ file.title.split('.')[0] }}
          </a>
        </div>
        <div
          class="doc-view__content"
          v-html="selectedDoc.description"
        />
      </div>
      <p v-else>
        Документ не найден
      </p>
    </template>

    <template v-else>
      <h1 class="ricotta">
        Документы
      </h1>
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model="search"
        before-icon="search"
      />

      <div
        v-for="doc in documents"
        :key="doc.id"
      >
        <p
          class="flex align-items-center sulguni color-rocky mt-6 pointer"
          @click="openDoc(doc.id)"
        >
          {{ doc.title }}<r-icon
            size="16"
            fill="rocky"
            class="ml-2"
            icon="arrow-right"
          />
        </p>
        <span class="edam opacity-72 mt-2">{{ formatDate(doc.created) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectedDocId: null,
      isShowContent: false,
      timeout: null,
      searchText: null
    };
  },
  computed: {
    documents() {
      const docs = this.items;

      if (this.searchText) {
        return docs.filter(el => (this.searchText ? el.title.toLowerCase().indexOf(this.searchText) !== -1 : true));
      }

      return docs;
    },

    selectedDoc() {
      const selected = this.items.filter(el => el.id === this.selectedDocId)[0];
      return selected?.id ? selected : null;
    },

    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 400);
      }
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString('Ru-ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    },
    openDoc(id) {
      this.selectedDocId = id;
      this.isShowContent = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.doc-view {
  width: 100%;
  &__file-cont {
    column-gap: 24px;
    display: flex;
  }
}

::v-deep .doc-view__content {
  h2 {
    margin-top: 24px;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    opacity: 0.72;
  }
  a {
    text-decoration: underline;
    color: var(--rir-rocky);
    text-decoration-color: var(--rir-rocky);
  }

  ul,
  ol {
    padding: 0 1.2rem;
  }
}
</style>
