import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';

const api = new Api();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    organizations: [],
    isOrganizationsLoading: true

    // types: null,
    // objects: null,
    // districts: [],
    // clinics: [],
    // staff: [],
    // selectedObject: null,
    // isObjectLoading: null,
    // isHasObjects: false
  },
  mutations: {
    setOrganizations(state, orgs) {
      state.organizations = orgs;
    },
    setOrgsLoading(state, status) {
      state.isOrganizationsLoading = status;
    },
    setRatingOrg(state, payload) {

    }
    // -----------------
    // setTypes(state, types) {
    //   state.types = types;
    // },
    // setObjects(state, objects) {
    //   state.objects = objects;
    // },
    // setDistricts(state, data) {
    //   state.districts = data;
    // },
    // setClinics(state, data) {
    //   state.clinics = data;
    // },
    // setStaff(state, data) {
    //   state.staff = data;
    // },
    // setSelectedObject(state, object) {
    //   state.selectedObject = object;
    // },
    // setObjectLoading(state, status) {
    //   state.isObjectLoading = status;
    //   status && (state.isHasObjects = true);
    // }
  },
  getters: {
    getOrganizationById: state => id => {
      console.log(state.organizations.find(el => Number(el.id) === Number(id)));
      return state.organizations.find(el => Number(el.id) === Number(id));
    }
    // -----------------
    // getTypes: (state) => {
    //   return state.objects.types;
    // },
    // getDocTypes: (state) => {
    //   return state.types?.doctorTypes || [];
    // },
    // getInstitutions: state => {
    //   return state.objects.types.clinics;
    // },
    // getInstitutByType: state => type => {
    //   return state.clinics.filter(el => String(el.type) === String(type));
    // },

    // getStaffById: state => id => {
    //   return state.staff.find(el => String(el.id) === String(id));
    // },
    // getDistrictById: state => id => {
    //   return state.districts.find(el => String(el.id) === String(id));
    // },
    // getInstitutionById: state => id => {
    //   return state.clinics.find(el => String(el.id) === String(id));
    // }
  },
  actions: {
    async getOrganizations(ctx) {
      // const res = await api.getTerritoryList({ currentMainMenuNumber: 10, casse: 2 });
      ctx.commit('setOrgsLoading', true);
      await api.getOrganizations().then(res => {
        ctx.commit('setOrganizations', res);
      }).finally(() => {
        ctx.commit('setOrgsLoading', false);
      });
      // console.log('orgs', res);
      // ctx.commit('setOrganizations', res.all);
    }
  },
  modules: {}
});
