<template>
  <ul class="share">
    <li
      v-for="link in links"
      :key="link.title"
      class="share__item"
    >
      <a
        class="share__link"
        :href="getShareLink(link.url)"
        rel="nofollow noopener"
        target="_blank"
        :title="link.title"
      >
        <img
          class="share__icon"
          :src="`${$S3_COMMON}/icons/social/${link.icon}_32.svg`"
        >
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          title: 'ВКонтакте',
          url: 'https://vk.com/share.php?url=##URL##&title=##TITLE##',
          icon: 'Vkontakte'
        },
        /** {
          title: 'Facebook',
          url: 'https://www.facebook.com/sharer.php?src=sp&u==##URL##&title=##TITLE##',
          icon: 'Facebook'
        }, */
        {
          title: 'WhatsApp',
          url: 'https://api.whatsapp.com/send?text=##TITLE## ##URL##',
          icon: 'WhatsApp'
        },
        {
          title: 'Telegram',
          url: 'https://t.me/share/url?url=##URL##&text=##TITLE##',
          icon: 'Telegram'
        },
        {
          title: 'Viber',
          url: 'viber://forward?&text=##TITLE## ##URL##',
          icon: 'Viber'
        }
        /* {
          title: 'Twitter',
          url: 'https://twitter.com/intent/tweet?text=##TITLE##&url=##URL##',
          icon: 'Twitter'
        } */
      ],
      url: '',
      tite: ''
    };
  },
  created() {
    this.url = encodeURI(window.location.href);
    this.title = encodeURI(document.title);
  },
  methods: {
    getShareLink(url) {
      return url.replace('##URL##', this.url).replace('##TITLE##', this.title);
    }
  }
};
</script>

<style lang="scss" scoped>
.share {
  display: flex;
  list-style: none;
  &__item {
    margin-right: 12px;
  }
  &__icon {
    width: 32px;
    height: 32px;
  }
}
</style>
