<template>
  <div class="accordion-clamp">
    <div
      class="accordion-clamp__content"
      :style="textStyle"
      ref="container"
    >
      <!-- В default слот помещается элемент или текст, который будет обрезаться -->
      <slot />
      <!-- В слот after помещается элемент, который необходимо показать после текста -->
      <slot
        v-if="isOpen"
        name="after"
      />
    </div>
    <button
      type="button"
      v-show="isOverFlow"
      class="accordion-clamp__btn inline-block sulguni mt-2"
      @click="isOpen = !isOpen"
    >
      {{ isOpen ? 'Свернуть' : 'Развернуть' }} описание<r-icon
        fill="rocky"
        :class="['accordion-clamp__btn-icon', { 'accordion-clamp__btn-icon--opened': isOpen }]"
        icon="arrow-up"
        :size="8"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'TextAccordion',
  props: {
    lines: {
      type: [String, Number],
      default: 3
    }
  },
  data: () => ({
    isOpen: null,
    isOverFlow: true,
    isMounted: false,
    clampElement: null
  }),
  computed: {
    textStyle() {
      if (!this.isMounted) return { '-webkit-line-clamp': Number(this.lines) };
      const maxHeight = Number(this.lines) * 24;
      const height = this.$refs.container.scrollHeight > maxHeight
        ? maxHeight
        : this.$refs.container.scrollHeight;
      return this.isOpen
        ? null
        : {
          '-webkit-line-clamp': Number(this.lines)
          // height: height + 'px',
          // maxHeight: maxHeight + 'px'
        };
    }
  },
  mounted() {
    this.isOverFlow = this.$refs.container.scrollHeight > this.$refs.container.clientHeight;
    this.isMounted = true;
    this.isOpen = !this.isOverFlow;
  }
};
</script>

<style lang="scss" scoped>
.accordion-clamp {
  &__btn {
    color: var(--rir-rocky);
    cursor: pointer;
    background: none;
  }
  &__btn-icon {
    margin-left: 8px;
    transition: 0.2s all;
    transform: translateY(-1px) rotate(180deg);
  }
  &__btn-icon--opened {
    transform: translateY(-1px) rotate(0deg);
  }
  &__content {
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden;
  }
}
</style>
