import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import rir from './plugins/RirLib';
import YmapPlugin from './plugins/vue-yandex-maps/vue-yandex-maps.umd';

const mapSettings = {
  apiKey: 'f8217e42-9b86-4033-8411-a7bf4f8d6835',
  lang: 'ru_RU',
  coordorder: 'longlat',
  version: '2.1'
};
Vue.prototype.$storage = window?.__CONFIG__?.storage || null;
Vue.prototype.$cityName = window?.__CONFIG__?.cityName || '';
Vue.prototype.$cityCenter = Array.isArray(window?.__CONFIG__?.cityCenter?.latLng)
  ? [...window?.__CONFIG__?.cityCenter?.latLng].reverse()
  : process.env.VUE_APP_CITY_CENTER.split(',').map(el => +el);

const S3_COMMON = window?.__CONFIG__?.storage
  ? `${window.__CONFIG__.storage}/common/vue`
  : process.env.VUE_APP_S3_COMMON_PATH;

Vue.prototype.$S3_COMMON = S3_COMMON;

Vue.prototype.$markerIcon = (iconName = 'marker') => ({
  layout: 'default#image',
  imageHref: `${S3_COMMON}/icons/map/${iconName}.svg`
});

Vue.use(YmapPlugin, mapSettings);
Vue.config.productionTip = false;

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions
          // for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        }
      });
    },
    router,
    store,
    $rir: rir
  }
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
