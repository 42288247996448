<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
        after-icon="search"
      />
    </div>
    <yandex-map
      v-if="isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      :options="mapOptions"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
      @balloonopen="clickBalloon()"
    >
      <slot />
    </yandex-map>

    <div
      v-show="control"
      class="r-map__controls flex flex-direction-column"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
        type="light"
        size="larishae"
        title=""
      />
      <drop-down-map-button
        v-if="showType"
        class="mb-4"
        :items="mapViewTypes"
        @select="onChangeView"
      />
      <r-button-simple
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';
import BalloonCard from './BalloonCard.vue';
import DropDownMapButton from './DropDownMapButton';

export default {
  name: 'RirMap',
  components: {
    BalloonCard,
    loadYmap,
    DropDownMapButton
  },
  props: {
    showType: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: true
    },
    center: {
      type: Array,
      default: () => [77.77823567390442, 64.92275052316926]
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },
    // Перетаскивание маркера
    markerDrag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      balloon: true,
      mapControls: [], // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },
      clusterOptions: {
        main: {
          //   clusterDisableClickZoom: true,
          hideIconOnBalloonOpen: false,
          clusterize: true,
          clusterHasHint: true,
          // ObjectManager принимает те же опции, что и кластеризатор.

          gridSize: 32,
          /*   clusterIconLayout: 'default#pieChart',
             iconPieChartCoreFillStyle: '#3D75E4',
             clusterIconPieChartStrokeWidth:6,
             clusterIconPieChartCoreRadius: 20,
             clusterIconPieChartRadius: 28, */
          clusterDisableClickZoom: false,
          clusterBalloonLayout: this.layoutFunc(),

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },

      //   coords: this.initCoords,
      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isYmapsReady: false,
      isCollapsed: true,
      position: 0,
      left: 0
    };
  },
  computed: {

  },
  async  mounted() {
    const _this = this;
    // настройки карты
    const settings = {
      apiKey: 'f8217e42-9b86-4033-8411-a7bf4f8d6835',
      lang: 'ru_RU',
      coordorder: 'longlat',
      version: '2.1'
    };
    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },
  methods: {
    clickBalloon() {
      const _this = this;

      const target = document.querySelectorAll('.next')[0];
      const targetPrev = document.querySelectorAll('.prev')[0];
      if (target) {
        target.onclick = function () {
          _this.moveElement(target, targetPrev);
        };
      }
      if (targetPrev) {
        targetPrev.onclick = function () {
          _this.moveElementPrev(target, targetPrev);
        };
      }
      document.addEventListener('DOMNodeInserted', event => {
        const target = document.querySelectorAll('.next')[0];
        const targetPrev = document.querySelectorAll('.prev')[0];
        if (target) {
          target.onclick = function () {
            _this.moveElement(target, targetPrev);
          };
        }
        if (targetPrev) {
          targetPrev.onclick = function () {
            _this.moveElementPrev(target, targetPrev);
          };
        }
      });
    },
    moveElement(click, clickPrev) {
      const next = Number(click.nextSibling.style.marginLeft.replace('px', ''));
      const glide = Number(document.querySelectorAll('.glide__slide').length);

      if (next / 224 != -glide - 1) {
        clickPrev.style.display = 'block';
        click.nextSibling.style.marginLeft = `${next - 224}px`;
      }

      if (next == -(glide - 2) * (224)) {
        click.style.display = 'none';
      }
    },
    moveElementPrev(click, clickPrev) {
      const prev = Number(clickPrev.nextSibling.nextSibling.style.marginLeft.replace('px', ''));

      if (prev != 0) {
        click.style.display = 'block';
        clickPrev.nextSibling.nextSibling.style.marginLeft = `${prev + 224}px`;
      }

      if (Number(click.nextSibling.style.marginLeft.replace('px', '')) == 0) {
        clickPrev.style.display = 'none';
      }
    },
    layoutFunc(item) {
      if (this.$route.query.id) {
        return [].join('');
      }
      return [
        '<div class="mobile_center">',
        '<div class="glide">',
        '<div class="glide__track" data-glide-el="track">',
        '<ul class="glide__slides">',
        '<button class="glider-next prev" style="margin-left: -14px; display: none;">',
        '<svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
        '<path d="M5.83776 2.04148C6.13682 1.57717 6.00285 0.958342 5.53854 0.659288C5.37077 0.55123 5.18282 0.499708 4.99708 0.499878C4.66876 0.500174 4.34732 0.661969 4.15633 0.958515L0.667321 6.37556C0.665633 6.37818 0.663949 6.3808 0.66227 6.38342L0.318578 6.91704C-0.106193 7.57654 -0.106193 8.42348 0.31858 9.08298L0.662295 9.61663C0.663967 9.61925 0.665643 9.62186 0.667324 9.62447L4.15633 15.0415C4.26847 15.2156 4.4256 15.3433 4.60267 15.4191C4.85237 15.5259 5.14174 15.5297 5.40067 15.4151C5.44778 15.3942 5.49387 15.3695 5.53854 15.3407C6.00285 15.0417 6.13682 14.4228 5.83776 13.9585L2.34874 8.5415C2.34777 8.53998 2.34679 8.53846 2.34583 8.53694C2.13733 8.20937 2.13733 7.79064 2.34583 7.46307L5.83776 2.04148Z" fill="#3D75E4"/>',
        '</svg>',
        '</button>',
        '<button class="glider-next next" style="margin-left: 205px;" id="navigate">',
        '<svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
        '<path d="M0.162462 2.04148C-0.136592 1.57717 -0.00262327 0.958342 0.461688 0.659288C0.62946 0.55123 0.817406 0.499708 1.00315 0.499878C1.33146 0.500174 1.6529 0.661969 1.8439 0.958515L5.33291 6.37556C5.33459 6.37818 5.33628 6.3808 5.33796 6.38342L5.68165 6.91704C6.10642 7.57654 6.10642 8.42348 5.68165 9.08298L5.33793 9.61663C5.33626 9.61925 5.33458 9.62186 5.3329 9.62447L1.8439 15.0415C1.73175 15.2156 1.57463 15.3433 1.39755 15.4191C1.14786 15.5259 0.858481 15.5297 0.599553 15.4151C0.55245 15.3942 0.506353 15.3695 0.461687 15.3407C-0.00262423 15.0417 -0.136592 14.4228 0.162463 13.9585L3.65148 8.5415C3.65246 8.53998 3.65343 8.53846 3.6544 8.53694C3.8629 8.20937 3.8629 7.79064 3.65439 7.46307L0.162462 2.04148Z" fill="#3D75E4"/>',
        '</svg>',
        '</button>',
        '{% for geoObject in properties.geoObjects %}',
        '<li onclick="return location.href = \'/service-area/info?id={{ geoObject.properties.balloonContentHeader.id|raw }}\'" class="glide__slide" style="padding: 16px; width: 224px;float: left;cursor: pointer;" >'
                + '<p style="text-align: left;font-weight: 700;font-size: 13px;line-height: 16px;">'
                + '{{ geoObject.properties.balloonContentHeader.title|raw }}</p>'
                + '<p style="text-align: left;margin-top: 4px;opacity: .72;font-size: 10px;line-height: 12px;font-weight: 400;">'
                + '{{ geoObject.properties.balloonContentHeader.name|raw }}'
                + '</p>'
                + '<a  style="cursor: pointer;" href="/create-route?toCoords='
                + '{{ geoObject.properties.balloonContentHeader.coordinates[0]|raw }},'
                + '{{ geoObject.properties.balloonContentHeader.coordinates[1]|raw }}' + '" class="flex mt-3">'
                + '<svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.48993 11.8704C10.8997 10.247 12 8.35041 12 6.54545C12 5.28648 11.5399 4.11033 10.7683 3.26857C10.0021 2.43263 9.00193 2 8 2C6.99807 2 5.99794 2.43263 5.23167 3.26857C4.46005 4.11033 4 5.28648 4 6.54545C4 8.35041 5.10026 10.247 6.51007 11.8704C7.02992 12.4691 7.55306 12.986 8 13.3951C8.44694 12.986 8.97008 12.4691 9.48993 11.8704ZM14 6.54545C14 11.6364 8 16 8 16C8 16 2 11.6364 2 6.54545C2 4.80949 2.63214 3.14463 3.75736 1.91712C4.88258 0.689608 6.4087 0 8 0C9.5913 0 11.1174 0.689608 12.2426 1.91712C13.3679 3.14463 14 4.80949 14 6.54545Z" fill="#3D75E4"/>',
        '<path d="M10 6C10 7.10457 9.10457 8 8 8C6.89543 8 6 7.10457 6 6C6 4.89543 6.89543 4 8 4C9.10457 4 10 4.89543 10 6Z" fill="#3D75E4"/>',
        '</svg>',
        '<span style="font-size: 13px;line-height: 16px;font-weight: 500;color: #3d75e4!important;">Построить маршрут</span>'
                + '</a>',
        '</li>',
        '{% endfor %}',
        '</ul>',
        '</div>',
        '</div>',
        '</div>'].join('');
    },
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onMapInit(e) {
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.$emit('mapInit', e);
      this.mapInstanse.options.set('openBalloonOnClick', false);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onLocation() {
      const { geolocation } = window.ymaps;
      geolocation.get({
        mapStateAutoApply: true
      }).then(result => {
        this.mapInstanse.geoObjects.add(result.geoObjects);
      });
    },
    onMapClick(e) {
      //   Закрываем открытый Balloon при клике по карте
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      if (e) {
        const coords = e.get('coords');
        this.$emit('click', coords);
      }
      this.baloon = false;
    },
    onRightClick(e) {
      this.$emit('onRightClick', e);
    },
    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};

</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    @media (max-width: 491px) {
      max-height: 100px;
    }
  }
  &__info {
    padding: 16px;
    @media (max-width: 491px) {
      width: 100%;
    }
  }
  @media (max-width: 491px) {
    width: 100%;
  }
}
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);

    > * {
      background-color: #fff;
    }
  }

  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }

    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }

  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}
@media (max-width: 491px) {
  ::v-deep  .mobile_center{
    display: flex;
    justify-content: center;
  }
}
// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}

::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  overflow: initial;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
  @media (max-width: 491px) {
    bottom: 0;
    transform: none;
    padding-bottom: 20px;
    border-radius: 0;
  }
}

@media (max-width: 405px) {
  ::v-deep .ymaps-2-1-79-copyrights-pane {
    bottom: 0;
  }
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 16px 16px 16px 0px;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
::v-deep .ymaps-2-1-79-balloon__layout {
  overflow: initial;
  border-radius: 16px 16px 16px 0px;
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}

.glide {
  width: 224px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  display: table-caption;
  padding-bottom: 5px;
  @media (max-width: 491px) {
    margin: 0 auto;
  }
}
.glide .glide__slides {
  display: flex;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.glide .glide__slide {
  flex: none;
  border-radius: 0.4em;
  text-align: center;
  line-height: 100px;
  list-style-type: none;
  height: 120px !important;
  @media (max-width: 491px) {
    width: 100%;
    height: 100px!important;
  }
}
.glider-next:hover {
  opacity: 1;
}
.glider-next {
  margin-right: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 8px rgb(4 21 62 / 12%);
  position: absolute;
  z-index: 2;
  padding: 0;
  color: #fff;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  border: 0;
  outline: none;
  cursor: pointer;
  opacity: 0.72;
  transition: color 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
</style>
