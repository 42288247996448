var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('loader'):_c('div',{staticClass:"orgs mt-8"},[_c('div',{staticClass:"orgs__cards"},_vm._l((_vm.objects),function(item){return _c('div',{key:item.id,staticClass:"flex"},[_c('router-link',{key:item.id,staticClass:"size",attrs:{"to":{
            name: 'info',
            query: { id: item.id },
          }}},[_c('object-card',{attrs:{"id":item.id,"name":item.title,"address":item.address,"img":item.img ? `/cityProperty/rest${item.img[0] === '/' ? item.img : '/' + item.img}` : null,"icon":"telephone"},on:{"mouseEnter":_vm.cardHover,"mouseLeave":_vm.cardHover}},[(item.phones && item.phones.length > 0)?_c('span',[_vm._v(_vm._s(item.phones[0]))]):_vm._e()])],1),_c('div',{key:_vm.count,on:{"click":function($event){return _vm.operateFavoriteCard(item.id, item.title)}}},[_c('r-tooltip',{attrs:{"title":!_vm.favoriteIds[item.id] ? 'в избранное' : 'Удалить из избранного',"position":"top-center","align-title":"end","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('r-icon',{staticClass:"mt-1 ml-auto pointer",attrs:{"icon":!_vm.favoriteIds[item.id] ? 'favorite-add' : 'favorite-add-active',"size":"16","fill":"rocky"}})]},proxy:true}],null,true)})],1)],1)}),0),_c('div',{staticClass:"orgs__map-cont"},[_c('r-input',{staticClass:"mb-6 orgs__input-search",attrs:{"label":"Поиск","before-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{ref:"map",class:['orgs__map', { 'orgs__map--collapsed': _vm.isMapCollapsed }]},[(_vm.isShowMap)?_c('rir-map',{attrs:{"search":"","center":_vm.mapCenter},on:{"mapInit":_vm.initMap,"markersWasAdd":_vm.markersWasAdd},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_vm._l((_vm.filteredObjects),function(marker){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(marker.id !== 'searchMarker'),expression:"marker.id !== 'searchMarker'"}],key:`org_${marker.id}_${_vm.search}`},[(marker.coordinates)?_c('ymap-marker',{attrs:{"coords":marker.coordinates,"marker-id":`org_${marker.id}`,"icon":_vm.$markerIcon(),"balloon":{
                header: {
                  id: marker.id,
                  img: marker.img ? `/cityProperty/rest/${marker.img}` : null,
                  title: marker.title,
                  name: marker.address,
                  coordinates: marker.coordinates,
                },
              },"options":{ hideIconOnBalloonOpen: false },"cluster-name":"main"},on:{"click":function($event){return _vm.onMarkerClick(marker.id)},"balloonopen":function($event){return _vm.bindListener($event, marker.id, _vm.favoriteIds[marker.id], marker.title)},"balloonclose":function($event){return _vm.unbindListener($event, marker.id, _vm.favoriteIds[marker.id], marker.title)}}},[_c('balloon-card',{attrs:{"slot":"balloon","title":marker.title,"address":marker.address,"img":marker.img ? `/cityProperty/rest/${marker.img}` : null,"data-id":marker.id,"id":marker.id,"favorite":_vm.favoriteIds[marker.id]},slot:"balloon"},[_c('a',{staticClass:"flex mt-3",attrs:{"href":'/create-route?toCoords=' + marker.coordinates.join(',')}},[_c('r-icon',{staticClass:"mr-2",attrs:{"size":"16","fill":"rocky","icon":"geopoint"}}),_c('span',{staticClass:"briscola color-rocky"},[_vm._v("Построить маршрут")])],1)])],1):_vm._e()],1)}),(_vm.searchCoordinates.coordinates)?_c('ymap-marker',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchCoordinates.id === 'searchMarker'),expression:"searchCoordinates.id === 'searchMarker'"}],attrs:{"coords":_vm.searchCoordinates.coordinates,"marker-id":`org_${_vm.searchCoordinates.id}`,"icon":_vm.$markerIcon(),"options":{ hideIconOnBalloonOpen: false },"cluster-name":"main"}}):_vm._e(),_vm._l((_vm.objects),function(district){return _c('div',{key:`district_${district.id}_${_vm.search}`},_vm._l((district.geometry),function(geometry,index){return _c('div',{key:`district_${index}_${_vm.search}`},[_c('ymap-marker',{attrs:{"marker-id":'district_' + district.id + '-' + index,"marker-type":"Polygon","coords":geometry,"hint-content":district.title,"marker-fill":{ color: district.id === _vm.polygonHoverId ? '#D06E0B' : '#3d75e4', opacity: 0.16 },"marker-stroke":{ color: district.id === _vm.polygonHoverId ? '#D06E0B' : '#3d75e4', width: 1 },"fill-rule":"nonZero","options":{
                  zIndex: 9,
                }},on:{"click":function($event){return _vm.pushTo(district.id)}}})],1)}),0)}),(!!_vm.filteredObjects && !!_vm.searchText)?_c('div',[_c('ymap-marker',{attrs:{"coords":[_vm.coordinates[0], _vm.coordinates[1]],"marker-id":'marker',"options":{
                hideIconOnBalloonOpen: false,
                draggable: false,
              },"icon":_vm.$markerIcon()}})],1):_vm._e()],2):_vm._e(),_c('button',{staticClass:"orgs__map-collapse-btn feta",on:{"click":function($event){_vm.isMapCollapsed = !_vm.isMapCollapsed}}},[_c('span',[_vm._v(" "+_vm._s(_vm.isMapCollapsed ? 'Развернуть' : 'Свернуть')+" карту ")])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }