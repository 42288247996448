<template>
  <div>
    <loader v-if="isLoading" />
    <page404 v-else-if="!organization" />
    <div
      v-else
      class="info flex justify-between"
      :key="organization.id"
    >
      <div class="info__description">
        <router-link
          :to="'/'"
          class="inline-flex align-items-center color-rocky"
          event
          @click.native.prevent="routeBack"
        >
          <r-icon
            size="16"
            fill="rocky"
            icon="arrow-left"
            class="mr-2"
          />
          Назад
        </router-link>
        <div
          class="flex align-items-center mt-6"
          :class="{ 'mb-5': false }"
        >
          <h1 class="taleggio">
            {{ organization.title }}
          </h1>
          <div :key="count">
            <r-icon
              @click.native.prevent="operateFavorite(organization.id, favoriteIds[organization.id], organization.title)"
              class="ml-3 pointer"
              :icon="!favoriteIds[organization.id] ? 'favorite-add' : 'favorite-add-active'"
              size="20"
              fill="rocky"
            />
          </div>
          <!-- <r-icon size="16" fill="rocky" class="ml-3" icon="favorite-add" size="20" /> -->
        </div>

        <div class="flex flex-col">
          <div>
            <p class="info__address feta mt-1 mb-3">
              {{ organization.address }}
            </p>
            <!-- <a class="flex align-items-center mt-2 mb-8 sulguni color-rocky" href="#"
              ><r-icon size="16" fill="rocky" class="mr-3" icon="geopoint" />Построить маршрут</a
            > -->
          </div>
          <a
            :href="`${organization.coordinates[0]
              ? '/create-route?toCoords=' + organization.coordinates[1] + ',' + organization.coordinates[0]
              : '/create-route?toCoords=' + $cityCenter[1] + ',' + $cityCenter[0]}`"
            class="flex mb-8 align-items-center"
          >
            <r-icon
              size="16"
              fill="rocky"
              class="mr-2"
              icon="geopoint"
            />
            <span class="sulguni color-rocky">Построить маршрут</span>
          </a>

          <div
            class="info__mob-map-cont"
            ref="mobMapCont"
          />
          <div
            class="open_image rounded-16 mb-8"
            v-if="organization.img"
            @click="openModalImage($event, { src: `/cityProperty/rest/${organization.img}`, name: organization.title })"
          >
            <img
              class="info__img"
              :src="`/cityProperty/rest/${organization.img}`"
              :alt="organization.title"
            >
            <r-icon
              class="r-icon"
              icon="search"
              size="56"
              fill="amelie"
            />
          </div>

          <div>
            <p class="feta">
              Всего домов – {{ organization.houseCount }}
            </p>
            <!-- <p class="feta mt-2">
              Домов с блокированной застройкой – {{ organization.blockHouseCount }}
            </p> -->
          </div>

          <div
            v-if="organization.description"
            class="mt-8"
          >
            <p class="feta opacity-48">
              Предоставляемые услуги
            </p>
            <text-accordion class="mt-2">
              <template #default>
                <p class="parmigiano">
                  {{ organization.description }}
                </p>
              </template>
            </text-accordion>
          </div>
        </div>

        <div :class="['info__contacts', 'color-rocky', 'mt-8']">
          <contact-list :contacts="contacts" />
        </div>

        <p
          class="info__work-hours parmigiano mt-8 text-left flex align-items-center"
          v-if="!!organization.workHours"
        >
          <r-icon
            size="16"
            fill="rocky"
            class="mr-2"
            icon="clock"
          />
          {{ organization.workHours }}
        </p>

        <a
          v-if="organization.documents && organization.documents.length"
          class="ext-link mt-8 inline-flex"
          href="#documents"
          @click.prevent="showDocuments"
        >Документы</a>

        <div
          v-if="transportStopList.length"
          class="info__transport mt-8"
        >
          <span class="info__transport-title feta"> Ближайшие остановки </span>
          <stops-list :list="transportStopList" />
          <button class="info__show-on-map sulguni">
            <r-icon
              size="16"
              fill="rocky"
              class="mr-2"
              icon="location"
            />
            Показать на карте
          </button>
        </div>

        <div :class="['info__rating', 'mt-8']">
          <h2 class="taleggio">
            Оцените учреждение
          </h2>
          <div
            @mouseleave="isVote && (selectedRating = selectedRatingOld)"
            class="mt-4 flex"
          >
            <div
              class="info__star mr-2"
              v-for="index in 5"
              :key="organization.id + '_' + index"
              @click="rateObject(index)"
              @mouseover="isVote && (selectedRating = index)"
            >
              <r-icon
                fill="rocky"
                :icon="((isVote ? selectedRating : organization.rating) >= index)
                  ? 'star-active'
                  : 'star'
                "
                size="32"
              />
            </div>
          </div>
          <span class="mt-2 mozzarella color-rocky">Средняя оценка: {{ organization.rating }}</span>
        </div>

        <div class="mt-8">
          <h2 class="taleggio mb-3">
            Поделиться
          </h2>
          <share />
        </div>
      </div>

      <div
        class="info__pc-map-cont"
        ref="pcMapCont"
      >
        <div
          :class="['info__map', { 'info__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="isShowMap && organization"
            :center="organization.coordinates || $cityCenter"
            :key="organization.id"
          >
            <ymap-marker
              v-if="organization.coordinates"
              :coords="organization.coordinates"
              :marker-id="'organization_' + organization.id"
              :icon="$markerIcon()"
              :options="{
                hasBalloon: false
              }"
            />

            <!-- Полигоны -->
            <div
              v-for="(geometry, index) in organization.geometry"
              :key="index"
            >
              <ymap-marker
                :marker-id="'org_' + organization.id + '_area' + index"
                marker-type="Polygon"
                cluster="districts"
                :coords="geometry"
                :balloon="{ header: {} }"
                :hint-content="organization.title"
                :marker-fill="{ color: '#3d75e4', opacity: 0.16 }"
                :marker-stroke="{ color: '#3d75e4', width: 1 }"
                :options="{
                  hasBalloon: false
                }"
              />
            </div>
          </rir-map>
          <button
            class="info__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
          <!--<a
            href="https://dgjn.yanao.ru/request/info/"
            class="mt-8px flex items-center"
            target="_blank"
          >
            <svg
              width="16"
              height="16"
              class="inline-block no-shrink color-rocky"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.88835e-05 3C5.88835e-05 1.34315 1.3432 0 3.00006 0H13.0001C14.6569 0 16.0001 1.34315 16.0001 3V10C16.0001 11.6569 14.6569 13 13.0001 13H5.33339L1.60006 15.8C1.15823 16.1314 0.53143 16.0418 0.200059 15.6C-0.131312 15.1582 -0.0417689 14.5314 0.400059 14.2L4.40006 11.2C4.57315 11.0702 4.78369 11 5.00006 11H13.0001C13.5523 11 14.0001 10.5523 14.0001 10V3C14.0001 2.44772 13.5523 2 13.0001 2H3.00006C2.44777 2 2.00006 2.44772 2.00006 3V11C2.00006 11.5523 1.55234 12 1.00006 12C0.447774 12 5.88835e-05 11.5523 5.88835e-05 11V3Z"
                fill="currentColor"
              />
              <path
                d="M4 5C4 4.44772 4.44772 4 5 4H11C11.5523 4 12 4.44772 12 5C12 5.55228 11.5523 6 11 6H5C4.44772 6 4 5.55228 4 5Z"
                fill="currentColor"
              />
              <path
                d="M5 7C4.44772 7 4 7.44772 4 8C4 8.55229 4.44772 9 5 9H9C9.55229 9 10 8.55229 10 8C10 7.44772 9.55229 7 9 7H5Z"
                fill="currentColor"
              />
            </svg>
            <div class="ml-6px text-c16 color-rocky">Написать обращение в контролирующий орган</div>
          </a>-->
        </div>
      </div>
    </div>

    <r-modal
      ref="modal"
      close-icon
    />
    <r-modal
      ref="modalImage"
      :fullscreen="true"
      close-icon
    />
  </div>
</template>

<script>
import Api from '../api/Api';
import TextAccordion from '../components/TextAccordion.vue';
import StopsList from '../components/StopsList.vue';
import ContactList from '../components/ContactList.vue';
import RirMap from '../components/RirMap.vue';
import Share from '../components/Share.vue';
import Page404 from './Page404';
import ModalDocView from '../components/ModalDocView.vue';
import ModalImage from '../components/ModalImage';
import Loader from '../components/Loader.vue';

export default {
  components: {
    Loader,
    TextAccordion,
    StopsList,
    ContactList,
    RirMap,
    Share,
    Page404
  },

  beforeRouteEnter(to, from, next) {
    const { name, path, query } = from;

    next(vm => {
      vm.prevRoute = { name, path, query };
    });
  },

  beforeRouteUpdate(to, from, next) {
    const { name, path, query } = from;
    this.prevRoute = { name, path, query };
    next();
  },
  data() {
    return {
      isVote: false,
      selectedRating: 0,
      selectedRatingOld: 0,
      isMapCollapsed: false,
      transportStopList: [],
      isFromMainPage: false,
      prevRoute: {},
      organization: {
        coordinates: []
      },
      isShowMap: false,
      favorite: [],
      favoriteIds: [],
      count: 0,
      isLoading: false
    };
  },
  computed: {

    coordinates() {
      return this.currentObject ? [this.currentObject.lat, this.currentObject.lng] : [];
    },

    contacts() {
      const contacts = [];
      const { phones } = this.organization;
      const { emails } = this.organization;
      const { web } = this.organization;
      if (phones?.length) {
        contacts.push({
          icon: 'telephone',
          contacts: phones.map(phone => ({
            title: phone,
            href: `tel:${phone.replace(/[^0-9+.]/g, '')}`
          }))
        });
      }

      if (emails?.length) {
        contacts.push({
          icon: 'email',
          contacts: emails.map(email => ({
            title: email,
            href: `mailto:${email}`
          }))
        });
      }

      if (web) {
        contacts.push({
          icon: 'link',
          contacts: [
            {
              title: web,
              href: web
            }
          ]
        });
      }

      return contacts;
    }
  },
  async mounted() {
    const api = new Api();
    this.favorite = await api.getFavorite();
  },

  // async mounted() {
  //   await this.getOrganization()
  // },

  async activated() {
    this.resetData();
    this.isShowMap = true;
    window.addEventListener('resize', this.onWindowResize);
    await this.getOrganization();
  },

  deactivated() {
    this.isShowMap = false;
    this.$refs.modal.closeModal();
    window.removeEventListener('resize', this.onWindowResize);
  },

  updated() {
    this.onWindowResize();
  },
  methods: {
    resetData() {
      this.organization = {
        coordinates: [],
        title: null,
        img: null,
        houseCount: null,
        blockHouseCount: null,
        address: null,
        description: null,
        workHours: null,
        web: null,
        phones: [],
        geometry: [],
        documents: [],
        active: false,
        updated: null,
        rating: null
      };
    },
    operateFavorite(id, value, title) {
      const api = new Api();
      api.setFavorite(id, title);
      this.favoriteIds[id] = !value;
      this.count++;
    },
    async openModalImage(e, image) {
      e.preventDefault();
      await this.$refs.modalImage.openModal(ModalImage, {
        img: image.src,
        name: image.name
      });
    },
    async getOrganization() {
      this.isLoading = true;

      const auth = await new Api().getAuth();
      this.isVote = auth.status !== -2;
      const company = await new Api().getRatingUser(+this.$route.query.id);
      this.selectedRating = company ? company.myVote : 0;
      this.selectedRatingOld = company ? company.myVote : 0;

      const org = await new Api().getOrganizationById(+this.$route.query.id);
      if (org) {
        org.geometry = org?.geometry?.coordinates?.length > 0 ? org.geometry.coordinates : [];
        org.coordinates = org?.coordinates?.coordinates?.length > 0 ? org.coordinates.coordinates : null;

        // org.coordinates && this.getNearbyTransport(org.coordinates[1], org.coordinates[0]);
      }
      if (this.$route.query?.id) {
        const { id } = this.$route.query;
        this.favoriteIds[id] = false;
        if (this.favorite.fav != undefined) {
          const fav = this.favorite.fav.filter(el => id == el.id);
          if (fav.length > 0) {
            this.favoriteIds[id] = true;
          }
        }
      }
      this.organization = { ...this.organization, ...org };
      this.isLoading = false;
    },
    routeBack() {
      this.prevRoute.name ? this.$router.go(-1) : this.$router.push('/');
    },
    async showDocuments() {
      await this.$rir.modal.open(ModalDocView, {
        items: this.organization.documents
      });
    },
    onWindowResize() {
      if (this.isLoading || !this.$refs.pcMapCont) return;
      const width = window.innerWidth;
      const { map } = this.$refs;
      if (width > 1023) {
        !this.$refs.pcMapCont?.hasChildNodes() && this.$refs.pcMapCont.appendChild(map);
        this.isMapCollapsed = false;
      } else {
        !this.$refs.mobMapCont.hasChildNodes() && this.$refs.mobMapCont.appendChild(map);
      }
    },
    async getNearbyTransport(lat, lng) {
      const params = {
        lat,
        lng
      };
      const res = await new Api().getTransport(params);

      this.transportStopList = [...res]
        .sort((a, b) => a.d - b.d)
        .map(el => ({
          name: el.name,
          routes: [
            ...Object.keys(el.routes).map(route => ({
              route,
              type: el.routes[route]?.vehicleTypes[0] || null
            }))
          ]
        }))
        .splice(0, 2);
    },
    async rateObject(rating) {
      if (this.isVote) {
        this.selectedRating = rating;
        this.selectedRatingOld = rating;
        const payload = {
          id: +this.$route.query.id,
          rating
        };
        await new Api().setRating(payload);
        const company = await new Api().getRatingUser(+this.$route.query.id);
        this.organization.rating = company.serviceCompany.rating;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .r-modal__content-r-icon_close {
  z-index: 1002;
}

.open_image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  //width: 100%;

  &:before {
    content: '';
    background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }

  img {
    z-index: -1;
  }

  .r-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      transform: translateY(0);
      opacity: 1;
    }

    .r-icon {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.info {
  &__description {
    text-align: left;
    width: 41.7%;
  }

  &__gallery {
    height: 234px;
  }

  &__work-hours {
    color: var(--rir-titanic);
  }

  &__transport-title {
    opacity: 0.48;
  }

  &__pc-map-cont {
    padding-left: 32px;
    width: 58.3%;
  }

  &__img {
    // height: 234px;
    // width: 375px;
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__mob-map-cont {
    display: none;
  }

  &__map {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    max-width: 100%;
    width: 100%;
    height: 648px;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__show-on-map {
    display: flex;
    align-items: center;
    background: none;
    margin-top: 18px;
    color: var(--rir-rocky);

    &:hover {
      cursor: pointer;
    }
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: none;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dotted #214eb052;
    }
  }

  &__star {
    cursor: pointer;
  }

  &__institution-link {
    &:hover {
      * {
        color: var(--rir-godfather);
      }

      // .info-address {
      //   color: var(--rir-codfather);
      // }
    }
  }
}

.ext-link {
  border-bottom: 2px solid var(--rir-alien);
}

::v-deep .r-modal__content {
  width: 100%;
  align-items: start;
}

@media screen and (max-width: 1023px) {
  .info {
    &__description {
      width: 100%;
    }

    &__map {
      position: relative;
      top: unset;
      margin-bottom: 52px;
      height: 447.31px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__mob-map-cont {
      display: block;
    }

    &__pc-map-cont {
      display: none;
    }

    &__map-collapse-btn {
      display: block;
    }
  }
}

@media screen and (max-width: 599px) {
  .info {
    &__map {
      position: relative;
      top: unset;
      margin-bottom: 52px;
      height: 360px;
    }
    &__map--collapsed {
      height: 144px;
    }
  }
}
</style>
