import axios from 'axios';
import Vue from 'vue';
import rir from '../plugins/RirLib';
export default class ApiService {
  token = null;
  _axios = null;
  constructor() {
    // this.token = Vue.prototype.$keycloak.kk.token;
    this._axios = axios.create({
      baseURL: process.env.VUE_APP_API_SERVER,
      // baseURL: 'https://test.rosatom.city/',
      // baseURL: '/api',
      headers: {
        'Content-Type': 'application/json'
        // common: {
        //   Authorization: `Bearer ${Vue.prototype.$keycloak.kk.token}`
        // }
      }
    });
    this._axios.interceptors.response.use(response => { // For status code with 2xx
      if (response?.data?.error) {
        rir.framework.notification.send({
          title: response?.data?.error || 'Ошибка!',
          seconds: 5,
          hiddenClose: false,
          iconOptions: {
            icon: 'warning',
            fill: 'fargo'
          }
        });
      }
      if (response?.error) {
        rir.framework.notification.send({
          title: response?.error || 'Ошибка!',
          seconds: 5,
          hiddenClose: false,
          iconOptions: {
            icon: 'warning',
            fill: 'fargo'
          }
        });
      }
      return response;
    }, error => { // Status code outside the range of 2xx
      // handle error cases
      console.error('error', error);
      return Promise.reject(error);
    });
  }
}
