<template>
  <div class="contact flex align-items-center">
    <r-icon
      size="16"
      fill="rocky"
      :icon="icon"
    />
    <a
      class="contact__link sulguni text-rocky"
      v-for="(contact, index) in contacts"
      :key="index"
      :href="icon !== 'link' ? contact.href : null "
      @click="icon === 'link' ? openLink(contact.href) : null"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ contact.title }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: ['contacts', 'icon'],
  methods: {
    openLink(link) {
      const r = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
      const test = r.test(link)
      window.open(test ? link : `http://${link}`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.contact {
  &__link {
    cursor: pointer;
    margin-left: 16px;

    &:first-of-type {
      margin-left: 8px;
    }
  }
}
</style>
