<template>
  <div
    class="transport-tag"
    :style="style"
  >
    <r-icon
      size="16"
      :icon="type"
      fill="amelie"
    />
    <span class="transport-tag__number sulguni">{{ this.route }}</span>
  </div>
</template>

<script>
export default {
  name: 'TransportTag',
  props: {
    route: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'bus'
    }
  },
  data() {
    return {
      colors: {
        bus: 'matrix',
        minibux: 'gentlemen',
        tram: 'snatch'
      }
    };
  },
  computed: {
    style() {
      return { backgroundColor: `var(--rir-${this.colors[this.type]})` };
    }
  }
};
</script>

<style lang="scss" scoped>
.transport-tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--rir-matrix);
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  &__number {
    margin-left: 6px;
    color: #fff;
  }
}
</style>
