import ApiService from './ApiService';

export default class Api extends ApiService {
  async getAuth() {
    const { data } = await this._axios.get('auth/rest/user');
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getOrganizations() {
    const { data } = await this._axios.get('cityProperty/rest/service/company');
    return data;
  }

  async getFavorite(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 5
    });
    return data;
  }

  async setFavorite(id, title) {
    const { data } = await this._axios.post('ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 5,
      id,
      title
    });
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post(
      `cityProperty/rest/service/company/vote/${payload.id}/${payload.rating}`,
      payload
    );
    return data;
  }

  async getOrganizationById(id) {
    const { data } = await this._axios.get(`/cityProperty/rest/service/company/${id}`);
    return data;
  }

  async getRatingUser(companyId) {
    const { data } = await this._axios.get(
      'cityProperty/rest/service/company/vote/result'
    );
    const company = data.find(el => el.serviceCompany.id === companyId);
    return company;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }
}
