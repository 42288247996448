<template>
  <div>
    <contact
      v-for="(contact, index) in contacts"
      class="mb-3"
      :key="index"
      :contacts="contact.contacts"
      :icon="contact.icon"
    />
  </div>
</template>

<script>
import Contact from './Contact.vue';

export default {
  name: 'ContactList',
  components: { Contact },
  props: ['contacts']
};
</script>

<style lang="scss" scoped></style>
